import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import FaqTemplate from '../templates/FaqTemplate';
import Helmet from 'react-helmet';

const HomePage = ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<Layout>
			<Helmet>
				<title>GridZeroSolar | FAQ</title>
			</Helmet>
			<FaqTemplate content={content} />
		</Layout>
	);
};

export default HomePage;

export const query = graphql`
	{
		allMarkdownRemark(filter: {frontmatter: {slug: {eq: "faq"}}}) {
			edges {
				node {
					html
					frontmatter {
						heading
						faqs {
							question
							answer
						}
						contact_heading
						contact_content
					}
				}
			}
		}
	}
`;